import './LandingPage.css'

const LandingPage = () => {
  return (
    <div className="container col-md-12">
<h1>Hello there ... This is a test site.</h1>
<h2>We're still working with our website.</h2>
    </div>
  )
}

export default LandingPage