import './Header.css';
const Header = () => {
  return (
    <header className='p-3 text-bg-dark'>
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            
            <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
            <div className="min-2"><h3>Wer2Charge&nbsp;</h3></div> <i className="fa-solid fa-plug min-2" />
            </a>

            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li><a href="#" className="nav-link px-2 text-secondary">Home</a></li>
            <li><a href="#" className="nav-link px-2 text-white">Products</a></li>
            <li><a href="#" className="nav-link px-2 text-white">Services</a></li>
            <li><a href="#" className="nav-link px-2 text-white">Blog</a></li>
            <li><a href="#" className="nav-link px-2 text-white">About</a></li>
            </ul>

            {/* <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
            <input type="search" class="form-control form-control-dark text-bg-dark" placeholder="Search..." aria-label="Search">
            </form>

            <div class="text-end">
            <button type="button" class="btn btn-outline-light me-2">Login</button>
            <button type="button" class="btn btn-warning">Sign-up</button>
            </div> */}
        </div>
    </header>
  )
}

export default Header