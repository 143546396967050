const Footer = () => {
  return (
    <footer className="my-4 py-3 text-bg-dark">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
        <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Home</a></li>
        <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Products</a></li>
        <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Services</a></li>
        <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Blog</a></li>
        <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">About</a></li>
        </ul>
        <div class="col-md-10 d-flex align-items-center">
      <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
        <svg className="bi" width="30" height="24"></svg>
      </a>
      <span className="mb-3 mb-md-0 text-muted">&copy; 2022 wer2charge.com&nbsp;</span>
      <i class="fa-solid fa-plug"></i>
      <a className="nav col-md-2 justify-content-end text-muted">Privacy Policy</a>
      <a className="nav col-md-2 justify-content-end text-muted">Terms & Conditions</a>
      <ul className="nav col-md-6 justify-content-end list-unstyled d-flex">
      <li className="ms-3"><a className="text-muted" href="#"><i className="fa-brands fa-facebook"></i></a></li>
      <li className="ms-3"><a className="text-muted" href="#"><i className="fa-brands fa-twitter"></i></a></li>
      <li className="ms-3"><a className="text-muted" href="#"><i className="fa-brands fa-youtube"></i></a></li>
    </ul>
    </div>


  
  
    </footer>
  )
}

export default Footer